import $ from 'jquery';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css'
import loader from './libs/loader.js'
import animating from './libs/animating';
import { gsap, ScrollToPlugin, ScrollTrigger } from "gsap/all"

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger); 

const home = {}

home.sliderHome = function(){
  const slider =  $('.slider-home .slider-wrap')
  slider.slick({
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnFocus: false,
    pauseOnHover: false,
    lazyLoad: 'ondemand',
		speed: 800

  })

}

home.sliderHomeMobile = function(){
	const slider =  $('.slider-home-mobile .slider-wrap')
	slider.slick({
	  arrows: false,
	  dots: true,
	  autoplay: true,
	  autoplaySpeed: 6000,
	  pauseOnFocus: false,
	  pauseOnHover: false,
	  lazyLoad: 'ondemand',
		  speed: 800
  
	})
  
}

home.sliderHomeNews = function(){
	const slider =  $('.home-news .list-news')
	slider.slick({
	  arrows: true,
	  dots: false,
	  autoplay: true,
	  autoplaySpeed: 5000,
	  lazyLoad: 'ondemand',
	  adaptiveHeight:true,
	  speed: 800
  
	})
  
}
if (typeof total_update_report == 'undefined'){
	var total_update_report=1;
}

home.sliderHomeUpdateReport = function(){
	const slider =  $('.report-update')
	slider.slick({
		arrows: false,
		dots: false,
		slidesToScroll:1,
		slidesToShow:total_update_report,
		lazyLoad: 'ondemand',
		infinite:true,
		autoplay: true,
		adaptiveHeight:true,
		responsive: [
		  {
			breakpoint: 1023,
			settings: {
			  slidesToShow: total_update_report == 1 ? 1 : 2,
			}
		  },
		  {
			breakpoint: 640,
			settings: {
			  slidesToShow: 1,
			}
		  }
		]
	})
  
}

home.animateScroll = function() {
	let animating_top = $(".animating-top"), //element
			animating_bottom = $(".animating-bottom"), //element
			animating_left = $(".animating-left"), //element
			animating_right = $(".animating-right"); //element

		animating($,gsap).from({
	    element: animating_top,
	    from: 'from-top', //animation type 'from-top','from-bottom','from-left','from-right','fade','swipe-horizontal','swipe-vertical'
	    time: .5, // time in secnd
	    ease: 'power3.easeOut' // easeing https://greensock.com/docs/v3/Eases
	  })
	  animating($,gsap).from({
	    element: animating_bottom,
	    from: 'from-bottom', //animation type 
	    time: .5, // time in secnd
	    ease: 'power3.easeOut' // easeing https://greensock.com/docs/v3/Eases
	  })
	  animating($,gsap).from({
	    element: animating_right,
	    from: 'from-right', //animation type 
	    time: .5, // time in secnd
	    ease: 'power3.easeOut' // easeing https://greensock.com/docs/v3/Eases
	  })
	  animating($,gsap).from({
	    element: animating_left,
	    from: 'from-left', //animation type 
	    time: .5, // time in secnd
	    ease: 'power3.easeOut' // easeing https://greensock.com/docs/v3/Eases
	  })
}


home.init = function(){
  
}

home.init()

window.onload = () => {
  loader($).init()
  home.sliderHome()
  home.sliderHomeMobile();
	home.sliderHomeNews();
	home.animateScroll();
	home.sliderHomeUpdateReport();
}