import { gsap } from "gsap"
import 'slick-carousel'; 

export default ($) => ({
	loader() {     
		let wrap = $(".loader"),
				wrap_bg = wrap.find('.innerLoad'),
				isiloader = wrap.find('.bg-load'),
				lines = wrap.find('.line-load span'),
				iload = 0,
				width = 0,
				load = $("body img").length;
		// vlo = 0; 
		let anim = function() {
			gsap.to(wrap, 0.4, {opacity: 1, display: 'block', delay: 0, onComplete: function () {
				gsap.to(lines, 5, { 
					width: '100%', 
					delay: 0, 
					// ease: SlowMo.ease.config (0.1, 0.3, false), 
					onComplete: function () {                                                   
						gsap.to(isiloader, .4, {
									top: '100%', 
									ease: "Power2.easeOut",
									delay: 0
							});
							gsap.to(wrap_bg, .4, {
									opacity:0,
									ease: "Power2.easeOut", 
									delay: 0
							});
							setTimeout(function () {
								// wrap.css('display', 'none');
								wrap.fadeOut('normal');
								// if($('.img-thumb').length){
								// 	$('.img-product .img-thumb').slick('slickGoTo', 0);
								// }
							}, 600);
					}});
					$('.counter-loading span').each(function () {
						$(this).prop('Counter',0).animate({
							Counter: $(this).attr('data-set')
						}, {
							duration: 5000,
							easing: 'swing',
							step: function (now) {
								$(this).text(Math.ceil(now));
							}
						});
					});
			}});
		}
		anim();
		if ($(".loader").length) {
				let delay = 0;
				$("body img").bind('load', function () {
						iload++;
						delay = iload;
						width = (iload / load) * 100;
						// vlo = 1;
						if (iload == load) {
								anim();
						}
				});
				anim();
		}   
  },    
  init(){
    this.loader()
  }
    
})
